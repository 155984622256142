.text-area-container {
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  z-index: 1;
}

.textarea-wrapper {
  width: 100%;
}

.text-area-container.bottom .textarea-wrapper {
  height: auto;
}

.text-area-container.bottom .background-blur {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  position: absolute;
  height: 95px;
  right: 16%;
  width: 87%;
  bottom: -20px;
}

.text-area-container.bottom.open .background-blur {

  right: 12%;
  width: 90%;
}

.text-area-container.bottom {
  position: fixed;
  bottom: 20px;
}

.text-area-container.bottom textarea {
  height: 66px;
}

.text-area-container textarea {
  width: 99%;
  height: 130px;
  resize: none;
  padding: 20px;
  font-weight: 400;
  font-size: 1.1rem;
  border: 0.5px solid #b2bcc6;
  border-radius: 15px;
  color: #6d7f92;
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.1),
    2px 2px 4px 2px rgba(0, 0, 0, 0.06);
  transition: 0.2s all;
}

.text-area-container textarea:hover {
  border: 0.5px solid #29394D;
  box-shadow: none;
  transition: 0.2s all;
}

.text-area-container textarea:focus {
  outline: none;
}

.send-button {
  position: absolute;
  right: 3%;
  height: 46px;
  margin-bottom: -54px;
  width: 80px;
  color: #b2bcc6;
  background-color: transparent;
  border: 1px solid #29394D;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.text-area-container.bottom .send-button {
  right: 11%;
  margin-bottom: 60px;
}

.text-area-container.bottom.open .send-button {
  right: 18%;
  margin-bottom: 60px;
}

.legis-button {
  position: absolute;
  right: 11%;
  height: 40%;
  width: 5%;
  color: #b2bcc6;
  background-color: transparent;
  border: 1px solid #29394D;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.client-button {
  position: absolute;
  right: 5%;
  height: 40%;
  width: 5%;
  color: #b2bcc6;
  background-color: transparent;
  border: 1px solid #29394D;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.send-button.active {
  background-color: #18A0FB;
  border: none;
}

.send-button.active:hover {
  background-color: #2495E1;
  border: none;
}

.legis-button.active {
  background-color: #18A0FB;
  border: none;
}

.client-button.active {
  background-color: #18A0FB;
  border: none;
}

.send-button.active svg {
  width: 90%;
  height: 90%;
  color: #ffffff;
  strokeWidth: 1.5px;
}

.legis-button.active svg {
  width: 60%;
  height: 60%;
  color: #ffffff;
  strokeWidth: 1.5px;
}

.client-button.active svg {
  width: 60%;
  height: 60%;
  color: #ffffff;
  strokeWidth: 1.5px;
}

.send-button svg {
  width: 90%;
  height: 90%;
  color: #29394d;
  strokeWidth: 1.5px;
}

.legis-button svg {
  width: 60%;
  height: 60%;
  color: #29394d;
  strokeWidth: 1.5px;
}

.client-button svg {
  width: 60%;
  height: 60%;
  color: #29394d;
  strokeWidth: 1.5px;
}


.text-area-container.bottom.open .textarea-wrapper {
  width: 67%;
  position: fixed;
  bottom: 17px;
  text-align: start;
}

.text-area-container.bottom .textarea-wrapper {
  width: 73%;
  position: fixed;
  bottom: 17px;
  text-align: start;
}

.textarea-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: not-allowed;
  z-index: 10;
  background-color: transparent;
  pointer-events: auto;
}

.text-area-container.disabled {
  opacity: 0.7;
  pointer-events: all;
}

.text-area-container.disabled textarea {
  cursor: not-allowed;
  background-color: #f5f5f5;
  pointer-events: none;
}

.text-area-container.disabled button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

/* Add these new styles */
.rating-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 100px;
  width: 100%;
  background-color: white;
  border: 1px solid #b2bcc6;
  border-radius: 15px;
  padding: 20px;
}

.rating-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #435162;
}

.ignore-button {
  background: none;
  border: none;
  color: #6d7f92;
  cursor: pointer;
  font-size: 0.9rem;
}

.ignore-button:hover {
  color: #435162;
  text-decoration: underline;
}

.stars-container {
  display: flex;
  gap: 12px;
}

.star {
  cursor: pointer;
  color: #E5E7EB;
  transition: all 0.2s ease;
}

.star svg {
  width: 32px;
  height: 32px;
}

/* Remove the styles we don't need anymore */
.text-area-container.rating-required .send-button {
  opacity: 0.5;
  cursor: not-allowed;
}

.text-area-container.rating-required textarea {
  cursor: not-allowed;
}

.input-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.text-area-container.bottom.open .prompt-button {
  right: 110px;
}

.text-area-container.bottom .prompt-button{
  bottom: 10px;
}

.prompt-button {
  position: absolute;
  right: 120px;
  bottom: 15px;
  height: 46px;
  width: 46px;
  color: #29394D;
  background-color: transparent;
  border: 1px solid #29394D;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.prompt-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.prompt-popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.prompt-popup hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.prompt-popup h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.prompt-popup textarea {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.prompt-popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.prompt-popup-buttons button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.predefined-prompts {
  display: flex;
  gap: 10px;
}

.pre-prompt-button  {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #F0F0F0F0;
  cursor: pointer;
  border: none;
}

.prompt-popup-buttons button:first-child {
  background-color: #f0f0f0;
}

.prompt-popup-buttons button:last-child {
  background-color: #007bff;
  color: white;
}

/*************************** Media Queries **************************/
@media only screen and (min-width: 360px) and (max-width: 600px) {

  .text-area-container {
    width: 95%;
  }

  .text-area-container.bottom .background-blur {
    height: 83px;
    right: 0%;
    width: 100%;
    bottom: -50px;
  }

  .text-area-container textarea {
    padding-right: 85px;
  }

  .text-area-container.bottom .send-button {
    margin-bottom: -52px;
    right: 6.5%;
    width: 46px;
    height: 46px;
  }

  .text-area-container.bottom {
    position: fixed;
    left: 0%;
    width: 100%;
    padding: 0 10px 20px 10px;
  }

  .text-area-container.bottom textarea {
    height: 58px;
    padding: 10px;
    padding-right: 110px;
  }

  .text-area-container.open .send-button {
    margin-right: 0%;
    margin-bottom: 5.3%;
  }

  .text-area-container.bottom .textarea-wrapper {
    width: 91%;
    left: 5%;
    position: fixed;
    bottom: 5px;
    text-align: start;
  }

  .text-area-container.bottom .prompt-button {
    right: 60px;
    bottom: 6px;
  }

  .prompt-button {
    right: 95px;
    bottom: 15px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .text-area-container.bottom.send-button {
    right: 17%;
    margin-bottom: 45px;
  }

  .text-area-container.bottom .background-blur {
    height: 102px;
    right: 9%;
    width: 92%;
    bottom: -50px;
  }

  .text-area-container.bottom {
    position: fixed;
    left: 2.5%;
    width: 95%;
    padding: 0 30px 20px 30px;
  }

  .text-area-container textarea {
    padding-right: 200px;
  }

  .text-area-container.bottom .send-button {
    right: 17.5%;
    margin-bottom: -20px;
  }

  .text-area-container.bottom.open .send-button {
    right: 11%;
    margin-bottom: -20px;
  }

  .text-area-container.open .send-button {
    margin-right: 0%;
    margin-bottom: 5.3%;
  }

  .text-area-container.bottom.open .textarea-wrapper {
    width: 79%;
    left: 10%;
    position: fixed;
    bottom: 17px;
    text-align: start;
  }

  .text-area-container.bottom .textarea-wrapper {
    width: 78%;
    left: 5%;
    position: fixed;
    bottom: 17px;
    text-align: start;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .text-area-container {
    width: 100%;
  }

  .text-area-container.bottom .background-blur {
    height: 100px;
    width: 85%;
  }

  .send-button {
    right: 12%;
    height: 46px;
    margin-bottom: -54px;
    width: 80px;
  }

  .text-area-container.bottom .send-button {
    right: 10.5%;
    margin-bottom: 20px;
  }

  .text-area-container.open .send-button {
    right: 6.5%;
    height: 46px;
    margin-bottom: -54px;
    width: 80px;
  }

  .text-area-container textarea {
    width: 80%;
    margin-left: 10%;
  }

  .text-area-container.open textarea {
    width: 96%;
    margin-left: 0;
  }

  .text-area-container.bottom {
    width: 100%;
  }

  .text-area-container.bottom textarea {
    width: 80%;
    margin-left: 10%;
  }

  .text-area-container.bottom {
    position: fixed;
    left: 0%;
    width: 100%;
    padding: 0 0 20px 0;
  }

  .text-area-container.bottom.open {
    width: 80%;
    margin-left: 32%;
  }

  .text-area-container.bottom.open textarea {
    width: 84.5%;
    margin-left: 2%;
  }

  .text-area-container.bottom.open .send-button {
    margin-right: 10.5%;
    margin-bottom: 20px;
    height: 47px;
    width: 80px;
  }

  .text-area-container.bottom.open .textarea-wrapper {
    width: 68%;
    left: 32%;
    position: fixed;
    bottom: 17px;
    text-align: start;
  }

  .text-area-container.bottom .textarea-wrapper {
    width: 95%;
    left: 5%;
    position: fixed;
    bottom: 17px;
    text-align: start;
  }

  .prompt-button {
    right: 20.5%;
  }

  .text-area-container.bottom.open .prompt-button {
    right: 27.5%;
}
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .text-area-container {
    width: 100%;
  }

  .send-button {
    right: 6.5%;
    margin-bottom: -57px;
  }

  .text-area-container.bottom .background-blur {
    height: 116px;
  }

  .text-area-container.bottom {
    width: 100%;
  }

  .text-area-container.bottom.send-button {
    right: 6.5%;
    margin-bottom: -5.5%;
  }

  .text-area-container.bottom.open .send-button {
    right: 15%;
  }

  .text-area-container.bottom.send-button {
    right: 0%;
    margin-bottom: 108px;
  }

  .text-area-container textarea {
    width: 90%;
    margin-left: 5%;
  }

  .text-area-container.bottom {
    position: fixed;
    bottom: 0%;
    left: 15%;
    width: 75%;
  }

  .text-area-container.bottom.open {
    margin-left: 20%;
    width: 75%;
    padding: 0 0 20px 0;
  }

  .text-area-container.bottom.open textarea {
    width: 88%;
    margin-left: 0%;
  }

  .text-area-container.bottom .send-button {
    right: 0%;
    margin-bottom: 100px;
  }

  .text-area-container.bottom.open .send-button {
    margin-right: 11.2%;
    margin-bottom: 61px;
  }

  .text-area-container.bottom.open .prompt-button {
    right: 24%;
  }

  .text-area-container.bottom.open .textarea-wrapper {
    width: 64%;
    position: fixed;
    bottom: 17px;
    text-align: start;
  }

  .text-area-container.bottom .textarea-wrapper {
    width: 80%;
    position: fixed;
    bottom: 17px;
    text-align: start;
  }

  .prompt-button {
    bottom: 10px;
    right: 15%;
  }
}


/* Add these new styles for the split-view prompt management */
.prompt-popup {
  max-width: 800px;
  width: 90%;
  padding: 2rem;
}

.prompt-management {
  display: flex;
  gap: 2rem;
  min-height: 400px;
}

.prompt-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 2rem;
  border-right: 1px solid #b2bcc6;
}

.prompt-form input,
.prompt-form textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #b2bcc6;
  border-radius: 8px;
  font-size: 1rem;
  color: #29394D;
}

.prompt-form textarea {
  flex: 1;
  resize: none;
}

.save-prompt-button {
  background-color: #18A0FB;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-prompt-button:disabled {
  background-color: #b2bcc6;
  cursor: not-allowed;
}

.save-prompt-button:hover:not(:disabled) {
  background-color: #2495E1;
}

.prompts-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.prompts-list h3 {
  margin-top: 0;
  color: #29394D;
  font-size: 1.2rem;
}

.prompts-container {
  flex: 1;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;
}

.prompt-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: rgba(242, 245, 248, 0.5);
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.prompt-item:hover {
  background-color: rgba(242, 245, 248, 0.8);
}

.prompt-item label {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.prompt-title {
  color: #29394D;
  font-weight: 500;
}

input[type="radio"] {
  width: 18px;
  height: 18px;
}

.prompt-actions {
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
}

.edit-button,
.delete-button {
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: opacity 0.2s ease;
}

.edit-button {
  background-color: #18A0FB;
  color: white;
}

.delete-button {
  background-color: #ff4757;
  color: white;
}

.edit-button:hover,
.delete-button:hover {
  opacity: 0.9;
}

.close-popup-button {
  margin-top: 2rem;
  align-self: flex-end;
  background-color: #29394D;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-popup-button:hover {
  background-color: #435162;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .prompt-management {
    flex-direction: column;
    min-height: auto;
  }
  
  .prompt-form {
    border-right: none;
    padding-right: 0;
    border-bottom: 1px solid #b2bcc6;
    padding-bottom: 2rem;
  }
  
  .prompts-container {
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .prompt-popup {
    width: 95%;
    padding: 1rem;
  }
  
  .prompt-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .prompt-actions {
    margin-left: 0;
    width: 100%;
    justify-content: flex-end;
  }
}

.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-confirmation-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
}

.delete-confirmation-modal h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.2rem;
}

.delete-confirmation-modal p {
  color: #666;
  margin: 1rem 0;
}

.confirmation-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cancel-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  color: #333;
  cursor: pointer;
  transition: background 0.2s;
}

.cancel-button:hover {
  background: #f5f5f5;
}

.confirm-delete-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #ff4444;
  color: white;
  cursor: pointer;
  transition: background 0.2s;
}

.confirm-delete-button:hover {
  background: #cc0000;
}