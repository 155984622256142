.inspiration {
  cursor: pointer;
  border: 1px solid #b2bcc6;
  background-color: #f1f2f4;
  border-radius: 12px;
  padding: 1%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
}

.inspiration:hover {
  border: 1px solid #18A0FB;
  transition: 0.2s all;
}

.inspiration p {
  margin: 0;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  color: #6d7f92;
}
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .inspiration {
    width: 100%;
    text-align: start;
    height: 50px;
    padding: 10px 10px 10px 10px;
  }
  .inspiration p {
    font-size: 0.7rem;
    font-weight: 700;
    color: #6d7f92;
  }
}
@media only screen and (min-width: 601px) and (max-width: 992px) {
  .inspiration {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inspiration p {
    font-size: 0.8rem;
    font-weight: 700;
    color: #6d7f92;
  }
}
