.chat-editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-editor-overlay.hidden {
  display: none;
}

.chat-editor {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.chat-editor.open {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.chat-editor-header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  border-bottom: solid 1px #E1E4E8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6D7F92;
  background-color: #F8F9FA;
  padding: 0 20px;
  z-index: 10;
}

.chat-editor-title {
  color: #29394D;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
}

.chat-editor-title h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.version-info {
  font-size: 12px;
  color: #6D7F92;
  margin-top: 4px;
}

.chat-editor-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #6D7F92;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
}

.action-button:hover {
  background-color: #E9ECEF;
}

.action-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.action-button:disabled:hover {
  background-color: transparent;
}

.chat-editor-close {
  cursor: pointer;
  color: #6D7F92;
  fill: #6D7F92;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-editor-content {
  padding: 20px 30px;
  color: #29394D;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.chat-editor-document {
  max-width: 800px;
  margin: 0 auto;
  background-color: #FFF;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: auto;
}

.document-content {
  font-size: 16px;
  line-height: 1.6;
}

.document-content.editable {
  width: 100%;
  height: auto;
  min-height: 300px;
  padding: 12px 16px;
  border: 1px solid #E1E4E8;
  border-radius: 8px;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.6;
  color: #29394D;
  background-color: #FFFFFF;
  outline: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: visible;
  white-space: pre-wrap;
}

.document-content.markdown-editor {
  width: 100%;
  height: calc(100vh - 150px);
  min-height: 300px;
  padding: 12px 16px;
  border: 1px solid #E1E4E8;
  border-radius: 8px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.6;
  color: #29394D;
  background-color: #FFFFFF;
  outline: none;
  resize: none;
}

.document-content.editable:focus {
  border-color: #18A0FB;
  box-shadow: 0 0 0 2px rgba(24, 160, 251, 0.2);
}

.document-content h1,
.document-content.editable h1 {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 16px;
  color: #29394D;
}

.document-content h2,
.document-content.editable h2 {
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 12px;
  color: #29394D;
}

.document-content h3,
.document-content.editable h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #29394D;
}

.document-content p,
.document-content.editable p {
  margin-bottom: 16px;
}

.document-content ul, 
.document-content ol,
.document-content.editable ul,
.document-content.editable ol {
  margin-bottom: 16px;
  padding-left: 24px;
}

.document-content li,
.document-content.editable li {
  margin-bottom: 8px;
}

.document-content code,
.document-content.editable code {
  background-color: #F1F3F5;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
}

.document-content pre,
.document-content.editable pre {
  background-color: #F1F3F5;
  padding: 16px;
  border-radius: 8px;
  overflow-x: auto;
  margin-bottom: 16px;
}

.document-content pre code,
.document-content.editable pre code {
  background-color: transparent;
  padding: 0;
}

/* Additional styles for blockquotes and tables */
.document-content blockquote,
.document-content.editable blockquote {
  border-left: 4px solid #E1E4E8;
  padding-left: 16px;
  margin-left: 0;
  margin-right: 0;
  color: #6D7F92;
}

.document-content table,
.document-content.editable table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;
}

.document-content th,
.document-content td,
.document-content.editable th,
.document-content.editable td {
  border: 1px solid #E1E4E8;
  padding: 8px 12px;
  text-align: left;
}

.document-content th,
.document-content.editable th {
  background-color: #F8F9FA;
}

@media (max-width: 768px) {
  .chat-editor {
    width: 100%;
  }
}