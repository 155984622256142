.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  min-height: 200px;
  width: 100%;
}

.error-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.error-content svg {
  width: 64px;
  height: 64px;
  color: #ff4d4f;
  margin-bottom: 1rem;
}

.error-content h2 {
  color: #434343;
  margin-bottom: 1rem;
}

.error-content p {
  color: #595959;
  margin-bottom: 1.5rem;
}

.retry-button {
  background-color: #18A0FB;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background-color: #1890ff;
} 