.container {
  position: relative;

  .cardRubric {
    display: flex;
    flex-direction: column;
    color: #29394D;
    gap: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    width: 204px;
    height: 216px;
    background: linear-gradient(170deg, #fff 80%, rgba(46, 137, 199, 0.4));

    .cardPublishDate {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      color: #6d7f92;
      cursor: pointer;
    }

    &:hover {
      color: #fff;
      background: linear-gradient(170deg, #29394d 80%, #2E89C7);
      animation: fade-in 1s;

      .cardPublishDate {
        color: #B2BCC6;
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .cardHeader {
      display: flex;
      justify-content: start;
      align-items: center;
      cursor: pointer;

      .cardType {
        color: #2E89C7;
        border-radius: 4px;
      }

      .cardSource {
        color: #2E89C7;
        border-radius: 4px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .cardBody {
      display: flex;
      flex-direction: column;

      .cardQuestion {
        width: 162px;
        height: 76px;
        font-size: 14px;
        line-height: 16.41px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }

      .cardFooter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 60%;
        position: absolute;
        bottom: 20px;

        .cardLogo {
          width: 58px;
          height: 34px;
          margin-right: 10px;
          background-color: #faf9fa;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: 5px;
        }

        .cardAuthorContainer {
          position: relative;
          width: 34px;
          /* Add space for the border */
          height: 34px;
          /* Add space for the border */
          display: inline-block;
          border-radius: 50%;
          /* Ensures the container is also circular */
          display: flex;
          /* Use flexbox to center content */
          justify-content: center;
          /* Horizontally center the image */
          align-items: center;
          /* Vertically center the image */
        }

        .cardAuthor {
          width: 30px;
          /* Original image size */
          height: 30px;
          background-color: #fff;
          border-radius: 50%;
          /* Keeps the image circular */
          display: block;
          position: relative;
          // z-index: 1; /* Ensures the image is above the border */
        }

        .cardAuthorContainer::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          background: linear-gradient(to top, #18A0FB, #06D9B1);
          /* Your gradient */
          padding: 4px;
          /* Border thickness */
        }
      }
    }
  }

  .cardArticle:hover {
    .cardPublishDate {
      color: #B2BCC6;
    }
  }

  .cardArticle {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid #ccc;
    color: #29394D;
    cursor: pointer;
    border-radius: 8px;
    padding: 15px;
    width: 204px;
    height: 216px;
    background: linear-gradient(170deg, #fff 80%, #4D949D);

    .cardPublishDate {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      color: #6d7f92;
      cursor: pointer;
    }

    &:hover {
      color: #ffffff;
      animation: fade-in 1s;
      background: linear-gradient(170deg, #29394d 80%, #4D949D);

      .cardPublishDate {
        color: #B2BCC6;
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .cardHeader {
      display: flex;
      justify-content: start;
      align-items: center;

      .cardType {
        color: #4D949D;
        border-radius: 4px;
      }
    }

    .cardBody {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .cardQuestion {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        /* Specify the box orientation */
        -webkit-line-clamp: 4;
        /* Limit to 3 lines */
        overflow: hidden;
        /* Hide the overflow text */
        text-overflow: ellipsis;
        /* Add "..." at the end */
        cursor: pointer;
        width: 162px;
        height: calc(16.41px * 4);
        font-size: 14px;
        line-height: 16.41px;
        /* Set line height */
        font-weight: 500;
        display: -webkit-box;
        /* Required for line clamping */
      }

      .cardFooter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 60%;
        position: absolute;
        bottom: 20px;

        .cardLogo {
          width: 58px;
          height: 34px;
          margin-right: 10px;
          background-color: #faf9fa;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: 5px;
        }

        .cardAuthorContainer {
          position: relative;
          width: 34px;
          /* Add space for the border */
          height: 34px;
          /* Add space for the border */
          display: inline-block;
          border-radius: 50%;
          /* Ensures the container is also circular */
          display: flex;
          /* Use flexbox to center content */
          justify-content: center;
          /* Horizontally center the image */
          align-items: center;
          /* Vertically center the image */
        }

        .cardAuthor {
          width: 30px;
          /* Original image size */
          height: 30px;
          background-color: #fff;
          border-radius: 50%;
          /* Keeps the image circular */
          display: block;
          position: relative;
        }

        .cardAuthorContainer::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          background: linear-gradient(to top, #18A0FB, #06D9B1);
          /* Your gradient */
          padding: 4px;
          /* Border thickness */
        }
      }
    }
  }

  .cardPdf {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 8px;
    padding: 15px;
    width: 204px;
    height: 216px;
    background: #fff;

    .cardHeader {
      display: flex;
      justify-content: center;
      align-items: center;

      .cardType {
        color: #ffffff;
        border-radius: 4px;
      }
    }

    .titlePdf {
      font-size: 14px;
      line-height: 16.41px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      bottom: 0;
      left: 0;
      width: 204px;
      height: 60px;
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 2px 0px 8px #94bde940;
      border-radius: 0 0 0 8px;

      p {
        max-width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        /* Added to handle text overflow */
      }
    }


    .cardPublishDate {
      font-style: italic;
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      margin-top: -40px;
      text-align: center;
      color: rgb(77, 148, 157);
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px;
    box-sizing: border-box;
    z-index: 2;
  }
}

/*********************** Media Queries **************************/

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .container {
    .cardRubric {
      width: 43vw;
      height: 26vh;

      .cardPublishDate {
        font-size: 10px;
        margin-top: 0;
      }

      .cardBody {
        .cardQuestion {
          font-size: 12px;
          width: 100%;
        }

        .cardFooter {
          bottom: 10px;

          .cardLogo {
            width: 48px;
            height: 30px;
            margin-right: 10px;
            background-color: #faf9fa;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 5px;
          }

          .cardAuthorContainer {
            position: relative;
            width: 32px;
            /* Add space for the border */
            height: 32px;
            /* Add space for the border */
            display: inline-block;
            border-radius: 50%;
            /* Ensures the container is also circular */
            display: flex;
            /* Use flexbox to center content */
            justify-content: center;
            /* Horizontally center the image */
            align-items: center;
            /* Vertically center the image */
          }

          .cardAuthor {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid #c6c6c6;
          }
        }
      }
    }

    .cardArticle {
      width: 43vw;
      height: 26vh;

      .cardPublishDate {
        font-size: 10px;
        margin-top: 0;
      }

      .cardBody {
        .cardQuestion {
          font-size: 12px;
          width: 100%;
        }

        .cardFooter {
          bottom: 10px;

          .cardLogo {
            width: 48px;
            height: 30px;
            margin-right: 10px;
            background-color: #faf9fa;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 5px;
          }

          .cardAuthorContainer {
            position: relative;
            width: 32px;
            /* Add space for the border */
            height: 32px;
            /* Add space for the border */
            display: inline-block;
            border-radius: 50%;
            /* Ensures the container is also circular */
            display: flex;
            /* Use flexbox to center content */
            justify-content: center;
            /* Horizontally center the image */
            align-items: center;
            /* Vertically center the image */
          }

          .cardAuthor {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid #c6c6c6;
          }
        }
      }
    }
    .cardPdf {
      width: 43vw;
      height: 26vh;

      .titlePdf {
        font-size: 12px;
        width: 43vw;
        height: 50px;
        position: absolute;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 2px 0px 8px #94bde940;
        border-radius: 0 0 8px 8px;
    }}

    .card {
      width: 170px;
      height: 170px;

      .cardHeader {
        .cardType {
          font-size: 18px;
          height: 5px;
          font-weight: 400;
          border-radius: 4px;
        }
      }

      .cardBody {
        .cardQuestion {
          font-size: 16px;
          line-height: 18px;
          height: 40px;
          font-weight: 400;
        }

        .cardFooter {
          margin-top: 5px;

          .cardLogo {
            width: 58px;
            height: 34px;
            margin-right: 10px;
            background-color: #faf9fa;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 5px;
          }

          .cardAuthor {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid #c6c6c6;
          }
        }
      }
    }
  }

  .container {
    .overlay {
      width: 100%;
      height: 100%;

      .closeIcon {
        cursor: pointer;
        position: absolute;
        font-size: xx-large;
        color: #fff;
        background-color: #c6c6c6;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        top: 0%;
        right: 0%;
        z-index: 21;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .container {
    .card {
      width: 170px;
      height: 170px;

      .cardHeader {
        .cardType {
          font-size: 18px;
          height: 5px;
          font-weight: 400;
          border-radius: 4px;
        }
      }

      .cardBody {
        .cardQuestion {
          font-size: 16px;
          line-height: 18px;
          height: 40px;
          font-weight: 400;
        }

        .cardFooter {
          margin-top: 5px;

          .cardLogo {
            width: 58px;
            height: 34px;
            margin-right: 10px;
            background-color: #faf9fa;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 5px;
          }

          .cardAuthor {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .container {
    .overlay {
      width: 100%;
      height: 100%;

      .closeIcon {
        cursor: pointer;
        position: absolute;
        font-size: xx-large;
        color: #fff;
        background-color: #c6c6c6;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        top: 0%;
        right: 0%;
        z-index: 21;
      }
    }
  }
}