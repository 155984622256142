.header_container {
  border-radius: 5px;
  background: #29394d;
  box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.1);
  padding: 1.5rem 1.75rem 1.875rem;

  position: relative;
  overflow: hidden;
}

.article_header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  width: 98%;
}

.content {
  position: relative;
  z-index: 1;
  color: #fff;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .type {
    padding: 0.5rem 0.875rem;
    border-radius: 5px;
    background: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  .edtBtn {
    border-radius: 5px;
    background: #fff;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #29394d;
    margin-left: 0.5rem;
    cursor: pointer;
    svg {
      height: 1rem;
    }
  }
  .chain {
    margin-left: auto;
    width: 58px;
    height: 34px;
    background-color: #faf9fa;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 5px;
  }
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
}

.description {
  font-size: 1rem;
  line-height: 22px;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 1rem 0 !important;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  h5 {
    font-size: 16px;
    line-height: 22px;
  }
  h6 {
    font-size: 14px;
    line-height: 20px;
  }
  table {
    width: 100% !important;
    border: 1px solid #ccc;
    max-width: 100%;
    margin: 0 0 10px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;

    tr {
      border: 1px solid #efefef;
    }
    tr:nth-child(2n) {
      border-bottom: 0;
      background-color: #f1f1f1;
    }
  }
  p {
    margin-bottom: 16px !important;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  ol,
  ul {
    list-style-position: inside;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-bottom: 5px;
  }
  a {
    color: #40a9ff;
  }
}

.actions {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;

  .btn {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #e1e4e8;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .share_bar {
    padding: 0.75rem 0.688rem 0;
    border-top: 1px solid #d8dde2;
    display: flex;
    align-items: center;
    .shareBtn {
      font-size: 0.75rem;
      font-weight: 500;
      cursor: pointer;
    }
    .likeBtn {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-size: 12px;

      font-weight: 500;
      svg {
        margin-right: 10px;
      }
    }
  }
}

.article_type {
  display: flex;
  align-items: center;
  margin-left: 1.25rem;
  .category {
    color: #fff;
    border-radius: 2px;
    height: 1.25rem;
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 0.75rem;
    margin-right: 1.5rem;
  }
  .type_community {
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #f1f2f4;
    }

    .community {
      display: block;
      margin-left: 0.75rem;
      padding-left: 0.75rem;
      border-left: 3px solid;
    }
  }
}

.publishedAt {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  span {
    color: #f1f2f4;
    font-size: 0.75rem;
  }
  .dot {
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background-color: #f1f2f4;
    margin: 0 9px;
  }
}
