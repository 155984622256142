.container {
  padding: 20px 50px;
  border-bottom: 1px solid #e1e4e8;
}
.top {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .title {
    font-size: 1.5rem;
    font-weight: 500;
    width: 90%;
  }
  .actions {
    display: flex;
    align-items: center;
  }
  .edit {
    padding: 0.25rem;
    border: 1px solid #29394d;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 0.5rem;
    svg {
      height: 0.75rem;
    }
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #18a0fb;
    }
  }
}

.closed {
  .arrow svg {
    color: #29394d;
    transform: rotate(180deg);
  }
}

.content {
  margin-top: 1.875rem;
  figure {
    padding: 0 !important;
  }
  iframe {
    height: 600px !important;
  }
}

.description {
  font-size: 1rem;
  line-height: 22px;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 1rem 0 !important;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  h5 {
    font-size: 16px;
    line-height: 22px;
  }
  h6 {
    font-size: 14px;
    line-height: 20px;
  }
  table {
    width: 100% !important;
    border: 1px solid #ccc;
    max-width: 100%;
    margin: 0 0 10px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;

    tr {
      border: 1px solid #efefef;
    }
    tr:nth-child(2n) {
      border-bottom: 0;
      background-color: #f1f1f1;
    }
  }
  p {
    margin-bottom: 16px !important;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  ol,
  ul {
    list-style-position: inside;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-bottom: 5px;
  }
  a {
    color: #40a9ff;
  }
}

.attachments {
  margin: 1rem -10px;
  .cardCont {
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .file_preview {
    cursor: pointer;
    width: 100%;
    height: 12rem;
    box-shadow: 2px 0px 8px #6d7f9240;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    &:hover {
      box-shadow: 2px 1px 10px #6d7f9276;
    }
    .image_preview {
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .video_preview {
      height: 100%;
      width: 100%;
    }
    .pdf_preview {
      height: 100%;
      width: 100%;
      transform: scale(1.1);
      overflow: hidden;
      border-radius: 8px;
      border: none !important;
    }
    .click_cover {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
    .title {
      width: 100%;
      height: 3rem;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #29394da7;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      font-size: 1rem;
      svg {
        height: 20px;
        margin-right: 0.5rem;
      }
    }
  }
}

.chain_author {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .chain {
    margin-right: 1.188rem;
    width: 60px;
    height: 40px;
    background-color: #faf9fa;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 5px;
  }
  .author {
    font-size: 1rem;
    font-weight: 500;
    color: #6d7f92;
    display: flex;
    align-items: center;
    margin-left: 2rem;
    .authorName {
      margin-right: 1.5rem;
    }
    .authorImg {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }
}

.answer_suggestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.313rem;
  .question {
    // display: flex;
    align-items: center;
    display: none;
    span {
      color: #6d7f92;
      font-size: 0.875rem;
      font-weight: 500;
      display: block;
      margin-right: 1rem;
    }
  }

  .btn {
    width: 80px;
    height: 40px;
    color: #18a0fb;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #c7e8fe;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.1);
    margin-right: 0.75rem;
  }
  .suggestBtn {
    margin-left: auto;
    border-radius: 5px;
    background: #f1f2f4;
    padding: 0.625rem 1.125rem;
    border: none;
    display: flex;
    align-items: center;
    color: #6d7f92;
    font-size: 0.875rem;
    font-weight: 500 !important;
    svg {
      margin-right: 0.688rem;
    }
  }
}

.spinner {
  margin-left: auto;
  height: 1.5rem;
}

.suggestion_exists {
  margin-left: auto;
  width: 350px;
  text-align: center;
  cursor: pointer;
  color: #6d7f92;
  font-size: 0.875rem;
  font-weight: 500;
  display: block;
}

.last_update {
  color: #6d7f92;
  font-size: 0.875rem;
  margin: 1rem 0;
}

.articleContainer {
  position: relative;
  margin-bottom: 1rem;
}

.articleOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.cardCont {
  padding: 0 7px;
  margin-bottom: 14px;
}

.img_container {
  width: 100%;
  height: 400px;
  margin-bottom: 1rem;
  .img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    border: 1px solid #6d7f924c;
  }
  .video {
    height: 100%;
    width: 100%;
  }
}
.files_container {
  margin: 0 -7px;
}
.fileContainer {
  width: 100%;
  height: 254px;
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #6d7f924c;
  padding: 1rem;
  .preview {
    height: 100%;
    overflow: hidden;
  }
  .file_preview {
    height: 100% !important;
    width: 180px;
    overflow: hidden !important;
    border-radius: 8px;
    border: none !important;
    transform: scale(1.3);
  }
  .ppt {
    transform-origin: 10% 26%;
  }
  .info {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title {
      font-weight: 500;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    .type {
      color: #6d7f92d0;
    }
    .download {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      margin-bottom: 2rem;
      height: 36px;
      width: 193px;
      background-color: #f1f2f4;
      color: #6d7f92;
      font-weight: 500;
      border-radius: 8px;
      svg {
        margin-right: 0.5rem;
      }
    }
  }
}

.youtubeContent {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  iframe {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .container {
    border-bottom: 1px solid #e1e4e8;
    width: 90vw;
  }
  .top {
    .title {
      margin-top: 20px;
      font-size: 1.25rem;
      font-weight: 500;
      width: 100%;
      text-align: justify;
    }
  }
  .description {
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 6px;
    }
  }
  .chain_author {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 601px) and (max-width: 992px) {
  .container {
    border-bottom: 1px solid #e1e4e8;
    width: 85vw;
  }
  .top {
    .title {
      margin-top: 20px;
      font-size: 1.25rem;
      font-weight: 500;
      width: 100%;
      text-align: justify;
    }
  }
  .description {
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 6px;
    }
  }
  .chain_author {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .container {
    border-bottom: 1px solid #e1e4e8;
    width: 85vw;
  }
  .top {
    .title {
      margin-top: 20px;
      font-size: 1.5rem;
      font-weight: 500;
      width: 100%;
      text-align: justify;
    }
  }
  .description {
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 6px;
    }
  }

}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .container {
    border-bottom: 1px solid #e1e4e8;
    width: 80vw;
  }
  .top {
    .title {
      margin-top: 20px;
      font-size: 1.5rem;
      font-weight: 500;
      width: 100%;
      text-align: justify;
    }
  }
  .description {
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 6px;
    }
  }

}
