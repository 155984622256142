$background-color: #f8f9fa;
$title-color: #29394d;
$text-color: #495057;
$subtext-color: #6c757d;

.maintenance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $background-color;
  color: $text-color;
  text-align: center;
  padding: 20px;

  .maintenance-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: $title-color;
    margin-bottom: 20px;
  }

  .maintenance-message {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: $text-color;
  }

  .maintenance-subtext {
    font-size: 1rem;
    color: $subtext-color;
    max-width: 500px;
  }
}
