.history-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.history-section h3 {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #6D7F92;
    margin-bottom: 0;
    padding-bottom: 0;
}

.question {
    display: grid;
    grid-row: 1;
    grid-template-columns: 1fr 8fr;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: fit-content;
    border: 1px solid #B2BCC6;
    border-radius: 12px;
    padding: 9px;
    margin-bottom: 5px;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #29394D;
    cursor: pointer;
    transition: 0.2s all;
}

.question:hover {
    border: 1px solid #29394D;
    background-color: #F1F2F4;
    transition: 0.2s all;
}

.question p {
    margin: 0;
    padding: 0;
    display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 3em;
    -webkit-line-clamp: 1;
}

.question svg {
    margin-right: 5px;
    width: 16px;
    height: 16px;
}