// Variables
$primary-color: rgb(24, 160, 251);
$white: #fff;
$gray-overlay: rgba(0, 0, 0, 0.5);
$panel-width: 95%;
$panel-height: 95%;
$shadow-color: rgba(0, 0, 0, 0.2);
$table-border-color: #ddd;
$table-bg: #f8f9fa;
$card-bg: #f8f9fa;

// Floating Button
.floating-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(24, 160, 251);
  color: #fff;
  z-index: 1000;
  border: none;
  border-radius: 25%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.floating-button:hover {
  background-color: #2495E1;
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray-overlay;
  z-index: 999;
}

// Panel
.panel {
  position: fixed;
  top: 2.5%;
  left: 2.5%;
  width: $panel-width;
  height: $panel-height;
  background-color: $white;
  box-shadow: 0 4px 8px $shadow-color;
  padding: 30px;
  border-radius: 8px;
  z-index: 1000;
  color: #3c4e64;
  overflow-y: auto;

  .filter-dropdown {
    flex: 1 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    transition: border-color 0.2s ease-in-out;
}

  .graphs {
    display: grid;
    padding: 20px 20px 20px 0px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  // Close Button
  .close-button {
    position: fixed;
    top: 6%;
    right: 4%;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #3c4e64;
  }

  .tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  
    button {
      padding: 10px 20px;
      margin-right: 10px;
      background: #f4f4f4;
      border: none;
      border-bottom: 2px solid transparent;
      color: #3c4e64;
      cursor: pointer;
  
      &.active {
        border-bottom: 2px solid $primary-color;
        font-weight: bold;
      }
  
      &:hover {
        background: #e0e0e0;
      }
    }
  }

  // Statistics Cards
  .stats {
    margin-top: 10px;
    display: flex;
    gap: 20px;
  
    .stat-card {
      background-color: #f8f9fa;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 20px;
      flex: none;
      width: 23.9%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .graph-container {
        .graph-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
  
          h3 {
            margin: 0;
            color: #3c4e64;
          }
  
          .time-filters {
            display: flex;
            gap: 10px;
            margin-bottom: 20px;
          }
        }
      }
  
      .progress-bar {
        width: 100%;
        height: 6px;
        background-color: #ddd;
        border-radius: 10px;
        margin-top: 10px;
  
        .progress-fill {
          height: 100%;
          background-color: rgb(24, 160, 251);
          border-radius: 10px;
        }
      }
    }
  }
  

  // Table Styles
  table {
    width: 100%;
    table-layout: fixed; // Ensures consistent column width
    margin-top: 10px;
    background-color: $table-bg;
    border-radius: 8px;
    overflow: hidden;
  
    th,
    td {
      padding: 8px;
      border: 1px solid $table-border-color;
      border-radius: 5px;
      text-align: center;
      overflow: hidden; // Hides overflowing content
      text-overflow: ellipsis; // Adds ellipsis for overflowing text
      word-break: break-word; // Breaks long words properly
      vertical-align: middle; // Ensures proper alignment
    }
  
    td {
      margin: 0;
  
      p {
        text-align: left;
        font-size: 0.75rem; // Slightly smaller text for answers/questions
        cursor: pointer;
        display: -webkit-box; // Enables the line clamping behavior
          -webkit-line-clamp: 2; // Clamps content to 2 lines
          -webkit-box-orient: vertical; // Ensures vertical stacking of lines
          overflow: hidden; // Hides content beyond the clamp
          text-overflow: ellipsis; // Adds ellipsis for overflow
          white-space: normal; // Allows text to wrap naturally
          margin: 0;
          width: 100%;
      }
    }
  
    th {
      background-color: #3c4e64;
      color: $white;
    }
  
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
  

  // Star Rating
  .star-rating {
    display: flex;
    justify-content: center;
    align-items: center;

    .star-svg {
      margin: 0 2px;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  
    span {
      font-size: 16px;
      color: #333;
    }
  
    .pagination-button {
      background-color: $primary-color; /* Primary blue */
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3; /* Darker blue */
      }
  
      &:disabled {
        background-color: #ccc; /* Gray when disabled */
        cursor: not-allowed;
      }
    }
  }

  .filters {
    display: flex;
    gap: 15px; /* Space between filter inputs */
    margin-bottom: 20px; /* Space below the filter section */
    margin-top: 10px;
    padding: 10px;
    background-color: #f8f9fa; /* Light gray background */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    input,
    select {
      flex: 1; /* Make inputs and select take equal space */
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 14px;
      color: #333;
      background-color: #fff;
      transition: border-color 0.2s ease-in-out;
  
      &:focus {
        border-color: rgb(24, 160, 251); /* Match the star color */
        outline: none;
        box-shadow: 0 0 3px rgba(24, 160, 251, 0.4);
      }
    }
  
    select {
      appearance: none; /* Remove default browser styles */
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 12px;
    }
  }
  
}

/*************************** Media Queries **************************/
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .floating-button{
    top: 3.1%;
    right: 3%;
    height: 38px;
    width: 38px;
    border-radius: 12px;
    z-index: 10;
    box-shadow: none;
  }
  .panel {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 5%;
    overflow-x: hidden;
    .graphs {
      grid-template-columns: repeat(1,1fr);
      gap: 15px;
      margin: 10px 0;
      
      > div {
        width: 100%;
        min-height: 300px;
        padding: 10px;
        background-color: #f8f9fa;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        canvas {
          width: 100% !important;
          height: auto !important;
          max-height: 250px;
        }
      }
    }
    .filters{
      flex-wrap: wrap;
      input{
        width: 40vw;
      }
    }
    .close-button {
      top: 3%;
      right: 4%;
      width: 30px;
      height: 30px;
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 1000;
    }
    h1{
      margin-top: 0%;
      padding-left: 0;
      text-align: start;
    }
    .stats {   
      flex-wrap: wrap;
      gap: 10px;
      .stat-card {
        width: 48%;
        padding: 12px;
        .card-content {
          h4{
            font-size: 14px;
          }
          .icon-container {
            width: 25px;
            height: 25px;
          }}
      }
    }

    table {
      font-size: 0.7rem;

      //hide the third column
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }
      th,
      td {
        p{
          font-size: 0.5rem;
          line-height: normal;
        }
        font-size: 0.5rem;
        padding: 4px;
      }
    }
  }
}