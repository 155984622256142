// Variables
$primary-color: rgb(24, 160, 251);
$white: #fff;
$gray-overlay: rgba(0, 0, 0, 0.5);
$panel-width: 95%;
$panel-height: 95%;
$shadow-color: rgba(0, 0, 0, 0.2);
$table-border-color: #ddd;
$table-bg: #f8f9fa;
$card-bg: #f8f9fa;

// Floating Button
.floating-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  color: $white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px $shadow-color;
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray-overlay;
  z-index: 999;
}

// Panel
.panel {
  position: fixed;
  top: 2.5%;
  left: 2.5%;
  width: $panel-width;
  height: $panel-height;
  background-color: $white;
  box-shadow: 0 4px 8px $shadow-color;
  padding: 30px;
  border-radius: 8px;
  z-index: 1000;
  color: #3c4e64;
  overflow-y: auto;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  // Close Button
  .close-button {
    position: absolute;
    top: 6%;
    right: 4%;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #3c4e64;
  }

  // Statistics Cards
  .stats {
    display: flex;
    gap: 20px;
  
    .stat-card {
      background-color: #f8f9fa;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 20px;
      width: 300px;
      flex: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .card-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        h4 {
          font-size: 16px;
          color: #3c4e64;
          margin-bottom: 5px;
        }
  
        p {
          font-size: 24px;
          font-weight: bold;
          color: #3c4e64;
        }
  
        .icon-container {
          width: 40px;
          height: 40px;
          background-color: #eef3f7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
  
          svg {
            width: 24px;
            height: 24px;
            color: #3c4e64;
          }
        }
      }
  
      .progress-bar {
        width: 100%;
        height: 6px;
        background-color: #ddd;
        border-radius: 10px;
        margin-top: 10px;
  
        .progress-fill {
          height: 100%;
          background-color: rgb(24, 160, 251);
          border-radius: 10px;
        }
      }
    }
  }
  

  // Table Styles
  table {
    width: 100%;
    table-layout: fixed; // Ensures consistent column width
    margin-top: 10px;
    background-color: $table-bg;
    border-radius: 8px;
    overflow: hidden;
  
    th,
    td {
      padding: 8px;
      border: 1px solid $table-border-color;
      border-radius: 5px;
      text-align: center;
      overflow: hidden; // Hides overflowing content
      text-overflow: ellipsis; // Adds ellipsis for overflowing text
      word-break: break-word; // Breaks long words properly
      vertical-align: middle; // Ensures proper alignment
    }
  
    td {
      margin: 0;
  
      p {
        text-align: left;
        font-size: 0.75rem; // Slightly smaller text for answers/questions
        cursor: pointer;
        display: -webkit-box; // Enables the line clamping behavior
          -webkit-line-clamp: 2; // Clamps content to 2 lines
          -webkit-box-orient: vertical; // Ensures vertical stacking of lines
          overflow: hidden; // Hides content beyond the clamp
          text-overflow: ellipsis; // Adds ellipsis for overflow
          white-space: normal; // Allows text to wrap naturally
          margin: 0;
          width: 100%;
      }
    }
  
    th {
      background-color: #3c4e64;
      color: $white;
    }
  
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
  

  // Star Rating
  .star-rating {
    display: flex;
    justify-content: center;
    align-items: center;

    .star-svg {
      margin: 0 2px;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  
    span {
      font-size: 16px;
      color: #333;
    }
  
    .pagination-button {
      background-color: $primary-color; /* Primary blue */
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0056b3; /* Darker blue */
      }
  
      &:disabled {
        background-color: #ccc; /* Gray when disabled */
        cursor: not-allowed;
      }
    }
  }
}

/*************************** Media Queries **************************/
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .panel{
    width: 100%;
    height: 100%;
    h1{
      margin-top: 0%;
      text-align: start;
    }
    .stats {    
      .stat-card {
        width: 49%;
      }
    }
  }
}
