.iframe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 10px;
  width: 70vw;
  height: 90vh;
  z-index: 10000;
}
.closeIcon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: xx-large;
  background-color: #ffffff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 3%;
  right: 14%;
  z-index: 100002;
}

/*************************** Media Queries **************************/
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .iframe {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .closeIcon {
    top: 1%;
    right: 3%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 992px) {
  .iframe {
    width: 80%;
  }
  .closeIcon {
    top: 3%;
    right: 7%;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .iframe {
    width: 80%;
  }
  .closeIcon {
    top: 3%;
    right: 8%;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .iframe {
    width: 85%;
  }
  .closeIcon {
    top: 3%;
    right: 6%;
  }
}
