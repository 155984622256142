.Main {
  height: 100vh;
  padding: 1%;
  width: 100%;
  background-color: #f4f7f9;
}

.content {
  position: relative;
  min-height: 100%;
  height: max-content;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e1e4e8;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-radius: 12px;
  padding: 10% 1% 1% 15%;
  transition: all 0.5s ease-in-out;
}

.content.bottom {
  padding: 3% 1% 10% 15%;
}

.content.open {
  /* text-align: center; */
  margin-left: 22%;
  width: calc(100% - 22%);
  padding-left: 3%;
  transition: all 0.3s ease-in-out;
}

.content h1 {
  font-size: 1.5rem;
  padding-bottom: 10px;
  color: #29394d;
  font-size: 40px;
  font-weight: 600;
}

.content h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #29394d;
  margin-bottom: 0;
  margin-top: 1.75rem;
  padding-bottom: 0;
}

.inspirations {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: start;
  width: 80%;
}

.header-open {
  position: absolute;
  color: #29394d;
  top: 3vh;
  left: 2%;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: center;
  width: 250px;
  justify-content: start;
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.logo-hide-history {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.header-open h3 {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.header-open img {
  width: 40px;
  height: 40px;
}

.discussion-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18a0fb;
  border-radius: 12px;
  border: none;
  color: #fff;
  cursor: pointer;
  height: 36px;
  width: 36px;
}

.discussion-btn:hover {
  background-color: #2495E1;
}

.discussion-btn img {
  width: 20px;
  height: 20px;
}

.span-beta {
  background-color: #ff8c27;
  color: white;
  padding: 2px 6px;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 4px;
  position: relative;
  top: -15px;
}

.toggle-btn-open {
  position: fixed;
  left: 3%;
  top: 40vh;
  background-color: #f4f7f9;
  border: 1px solid #b2bcc6;
  margin: 0;
  padding: 1px;
  border-radius: 12px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  rotate: 180deg;
  animation: fade-in 1s;
  transition: 0.2s all;
}

.toggle-btn-open:hover {
  background-color: #F1F2F4;
  border: 1px solid #29394d;
  transition: 0.2s all;
}

.model-select{
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #b2bcc6;
  position: fixed;
  top: 40px;
  right: 40px;
}

.text-area-container.bottom.open .rating-container{
  width: 75%;
  margin-right: 22%;
}

/************** Media queries **************/

@media only screen and (min-width: 360px) and (max-width: 700px) {
  .Main {
    flex-direction: column;
    width: 100%;
    padding: 0%;
  }

  .open {
    width: 100%;
    position: relative;
  }

  .content {
    align-items: center;
    padding: 0;
    height: auto;
    text-align: start;
    width: 100%;
  }

  .content.open {
    display: none;
  }

  .content.bottom {
    padding: 5% 5% 20% 5%;
  }

  .inspirations {
    display: flex;
    flex-direction: column;
  }

  .content h1 {
    margin-top: 30%;
    width: 95%;
    padding-left: 10px;
    font-size: 24px;
    color: #29394d;
    font-weight: 600;
  }

  .header-open {
    flex-direction: row-reverse;
    position: fixed;
    gap: 3rem;
    justify-content: space-around;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px 10px;
    z-index: 1;
  }

  .toggle-btn-open {
    position: relative;
    top: 3%;
    left: 0;
  }

  .bottom {
    margin-bottom: 20px;
  }

  .fade-h3 {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .Main {
    display: flex;
    flex-direction: row;
  }

  .open {
    position: fixed;
    width: 50%;
    z-index: 4;
  }

  .content {
    position: absolute;
    align-items: center;
    padding: 5px;
    height: auto;
    text-align: center;
    z-index: 3;
    width: 98%;
  }

  .content.open {
    position: absolute;
    z-index: 2;
    width: 77%;
  }

  .inspirations {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }

  .inspirations :nth-child(3) {
    grid-column: span 2;
  }

  .content h1 {
    width: 90%;
    margin-top: 25%;
    font-size: 2rem;
    color: #29394d;
    font-weight: 600;
  }

  .header-open {
    flex-direction: row-reverse;
    justify-content: space-around;
    width: 100%;
    top: 0;
    left: 0;
    gap: 3rem;
    background-color: rgba(255, 255, 255, 1);
    padding-top: 20px;
    z-index: 1;
  }

  .toggle-btn-open {
    position: relative;
    top: 3%;
    left: 0;
  }

  .bottom {
    margin-bottom: 20px;
  }

  .fade-h3 {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .open {
    position: fixed;
    width: 30%;
  }

  .content {
    position: relative;
    align-items: center;
    padding: 5px;
    height: auto;
    text-align: center;
  }

  .content.open {
    margin-left: 30%;
    width: 70%;
    z-index: 4;
  }

  .content.open .inspirations {
        display: grid;
        grid-template-columns: 40% 60%;
        gap: 10px;
        margin-right: auto;
        width: 95%;
  }

  .inspirations {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 10px;
  }

  .inspirations :nth-child(3) {
    grid-column: span 2;
  }

  .content h1 {
    margin-top: 150px;
    font-size: 40px;
    color: #29394d;
    font-weight: 600;
  }

  .header-open {
    flex-direction: row-reverse;
    justify-content: space-around;
    width: 100%;
    top: 0;
    left: 0;
    gap: 3rem;
    background-color: rgba(255, 255, 255, 1);
    padding-top: 20px;
    z-index: 1;
  }

  .toggle-btn-open {
    position: relative;
    left: 0;
    top: 3%;
  }

  .bottom {
    margin-bottom: 0px;
  }

  .fade-h3 {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .open {
    position: fixed;
    width: 30%;
  }

  .content.open {
    position: relative;
    margin-left: 30%;
    width: 70%;
  }

  .content h1 {
    font-size: 40px;
    font-weight: 600;
    width: 80%;
    margin-left: 5%;
}

  .content h2 {
    font-size: 1.2rem;
    width: 80%;
    margin-left: 5%;
  }

  .inspirations {
    padding-top: 10px;
    width: 90%;
    margin-left: 5%;
  }

  .header-open {
    position: fixed;
    color: #29394d;
    top: 5%;
    left: 3%;
  }
}