.pdfViewer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 5%;
  width: 70%;
  height: 90%;
  z-index: 10;
}
.closeIcon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: xx-large;
  background-color: #ffffff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 2%;
  color: #3c4e64;
  right: 13.5%;
  z-index: 12;
}

/*************************** Media Queries **************************/
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .iframe {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .closeIcon {
    z-index: 100001;
    top: 1%;
    right: 3%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 992px) {
  .pdfViewer {
    width: 80%;
  }
  .closeIcon {
    top: 3%;
    right: 7%;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .pdfViewer {
    width: 80%;
  }
  .closeIcon {
    top: 3%;
    right: 8%;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .pdfViewer {
    width: 85%;
  }
  .closeIcon {
    top: 3%;
    right: 6%;
  }
}
