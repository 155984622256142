.thumbnailContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 204px;
    height: 216px;
    border: 1px solid #ccc;
    margin: -50px 0 0 -16px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #eaf3fb8f;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thumbnailImage {
    width: 100%;
    height: 115%;
    border-radius: 8px;
    max-width: 100%;
    max-height: 120%;
    object-fit:fill;
    padding: 18px 0 0 0;
}
.loader {
    position: relative;
    width: 40px;
    height: 40px;
}

.loader::before, .loader::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-radius: 50%;
}

.loader::before {
    border-top-color: #3498db; 
    animation: spinClockwise 2s linear infinite;
}

.loader::after {
    border-right-color: #e67e22; 
    animation: spinCounterclockwise 2s linear infinite;
}

@keyframes spinClockwise {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spinCounterclockwise {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
    .thumbnailContainer {
        width: 45vw;
        height: 26vh;
        margin: -50px 0 0 -16px;
    }
    
}