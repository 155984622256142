.contentContainer {
  border-radius: 5px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 4rem;
  width: 70%;
  height: 90%;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.questionsList {
  position: absolute;
  border-top: 1px solid #000000;
  z-index: 40;
  max-width: 970px;
}

/************************ Media Queries **************************/
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .contentContainer {
    padding: 1.5rem;
    width: 100%;
    height: 100%;
  }
  .questionsList {
    max-width: 370px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .contentContainer {
    padding: 1rem;
    width: 90%;
    height: 90%;
  }
  .questionsList {
    max-width: 800px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .contentContainer {
    padding: 1.5rem;
    width: 90%;
    height: 90%;
  }
  .questionsList {
    max-width: 1100px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .contentContainer {
    padding: 1.5rem;
    width: 85%;
    height: 90%;
  }
  .questionsList {
    max-width: 1300px;
  }
}
