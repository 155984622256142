.show-details {
  padding: 20px;
  line-height: 2rem;
}

/* Styles for vertical split layout */
.show-details-split {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.left-pane {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  box-sizing: border-box;
}

.right-pane {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-left: 2px solid #ddd;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  box-sizing: border-box;
}

.left-pane h2,
.right-pane h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.left-pane p,
.right-pane p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Model selector styling in the right pane */
.model-selector {
  margin-bottom: 20px;
}

.model-selector label {
  font-weight: bold;
  margin-right: 10px;
}

.model-selector select {
  padding: 5px;
  font-size: 1rem;
}

.compare-button {
  margin-left: 10px;
  padding: 5px 15px;
  background-color: #0070f3;
  color: #fff;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.compare-button:hover:not(:disabled) {
  background-color: #005bb5;
}

.compare-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.comparison-results {
  margin-top: 20px;
}

.result-column {
  margin-bottom: 20px;
}

.result-column h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #333;
}

/* Existing styles preserved */
.thumbnailContainer {
  margin: -63px 0 0 -16px;
}

.show-question {
  width: 90%;
}

.show-answer {
  width: 90%;
  ol {
    margin-left: 40px !important;
  }
  ul {
    margin-left: 40px !important;
  }
}

.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 30px;
}

.cards p {
  width: 100%;
}

/* Responsive adjustments */
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .show-details {
    padding: 0px;
  }
  .show-question {
    width: 100%;
  }
  .show-answer {
    width: 100%;
    text-align: justify;
  }
  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .show-details-split {
    flex-direction: column;
    padding: 10px;
  }
  .left-pane,
  .right-pane {
    padding: 15px;
  }
  .right-pane {
    border-left: none;
    border-top: 2px solid #ddd;
  }
}