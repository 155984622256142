.sidebar {
  width: 23%;
  height: 100%;
  background-color: #f4f7f9;
  position: fixed;
  padding:1rem;
  left: 0;
  top: 0;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  color: #29394d;
}

.sidebar.open {
  transform: translateX(0);
}

.header,
.fixed-content {
  width: 100%;
  background-color: #f4f7f9;
  position: sticky;
  z-index: 10;
  height: 77px;
}

.header {
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  font-size: 1.125rem;
}

.fixed-content {
  top: 78px;
  padding: 0px 20px 0px 20px;
  font-size: 1.125rem;
}

.logo {
  display: flex;
  width: 100%;
  justify-content: start;
  justify-items: center;
  align-items: center;
  position: relative;
}

.logo h3 span {
  background-color: #ff8c27;
  color: white;
  padding: 2px 6px;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 4px;
  position: relative;
  top: -15px;
}

.logo img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.toggle-btn {
  background-color: #f4f7f9;
  border: 1px solid #b2bcc6;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  width: 38px;
  height: 35px;
  transition: 0.2s all;
}

.toggle-btn:hover {
  background-color: #F1F2F4;
  border: 1px solid #29394d;
  transition: 0.2s all;
}

.toggle-btn img {
  width: 30px;
  height: 30px;
}

.discussions-container {
  padding: 5px 20px 20px 20px;
}

.fixed-content button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #18a0fb;
  border-radius: 12px;
  color: #fff;
  border: none;
  cursor: pointer;
  height: 36px;
  font-weight: 600;
}

.fixed-content button:hover {
  background-color: #2495E1;
}

.fixed-content button svg {
  margin-right: 5px;
  color: #fff;
}

.parent svg path {
  fill: currentcolor;
}

.discussions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.discussions h2 {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.discussions-container ul {
  list-style-type: none;
  padding: 0;
}

.version {
  text-align: center;
  margin-top: 10px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #6d7f92;
}

::-webkit-scrollbar {
  width: 8px;
  display: none;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c4c4c4;
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .sidebar {
    padding: 2rem 0rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .sidebar {
    padding: 2rem 0rem;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .sidebar {
    padding: 2.5rem 0rem;
  }
}