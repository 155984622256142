.user-question-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding: 0px 20px 20px 20px;
  /* box-shadow: rgba(168, 168, 168, 0.5) 0px -6px 6px -6px; */
}

.user-question {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.user-question img {
  width: 36px;
  height: 36px;
  border: 0.5px solid #B2BCC6;
  border-radius: 50%;
}

.user-question h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.separator {
  width: 2px;
  /* Thickness of the vertical line */
  height: 64px;
  /* Adjust height to match content */
  margin-top: 15px;
  background-color: #ccc;
  /* Color of the line */
  align-self: stretch;
  /* Make it fill available vertical space */
}

.user-question-container .user-question p {
  text-align: start;
  width: 80%;
}

.ai-response {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.ai-response svg {
  width: 36px;
  height: 36px;
  border: 0.5px solid #B2BCC6;
  padding: 2px;
  border-radius: 50%;
}

.ai-response h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.border-bottom {
  width: calc(100vw - 320px);
  height: 1px;
  background-color: #29394D;
  margin-top: 10px;
}

.answer .border-bottom {
  width: calc(100vw - 320px);
  height: 1px;
  position: absolute;
  background-color: #E1E4E8;
  margin-top: 0px;
}

.answer {
  margin-top: 10px;
  font-size: 1em;
  line-height: 1.5rem;
  font-weight: 400;
  color: #435162;
  width: 80%;
}

.answer h3 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.answer span p {
  width: 85%;
}

.answer p:last-child {
  margin-top: 5px;
}

.answer pre {
  width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
}

.answer table {
  border-collapse: collapse;
}

.answer td {
  border: 1px solid #808995;
  text-align: center;
  padding: 10px;
}

.answer th {
  border: 1px solid #808995;
  text-align: center;
  padding: 10px;
}

.answer ul {
  padding: 0;
  margin: 0;
  list-style: revert;
}

.answer li {
  padding-top: 5px;
  margin-left: 15px;
  margin-bottom: 2px;
  width: 90%;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.action-buttons .action-button {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 12px;
  background-color: #f1f2f4;
  cursor: pointer;
  border: 0.5px solid #B2BCC6;
  transition: all 0.2s ease;
}

.action-buttons .action-button:hover {
  background-color: #e1e4e8;
}

.date {
  margin: 0;
  padding-top: 5px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #6d7f92;
}

.sources {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: start;
  margin: 0;
  padding-top: 10px;
  margin-top: 15px;
}

.sources-cards {
  padding-top: 10px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  /* Two columns for the grid */
  gap: 20px;
  /* Space between grid items */
  justify-items: start;
  align-items: start;
}

.same-rubric-questions {
  grid-column: span 4;
}

.sources h2 {
  margin: 0;
}

.cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 30px;
}

.cards p {
  width: 100%;
}

.questions-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  align-items: center;
}

.questions-list svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.questions-list svg.rotate {
  animation: rotate 1s;
  rotate: 180deg;
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 180deg;
  }
}

.list {
  /* list-style-type: none; */
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 100%;
}

.list li {
  border-bottom: 1px solid #808995;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #435162;
  width: 100%;
  padding: 5px;
  gap: 15px;
  color: black;
  cursor: pointer;
}

.list li:last-child {
  border: none;
}

.list li:hover {
  background-color: #dbeafe;
}

.skeleton-container {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
  z-index: 0;
}

.skeleton-author-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
  z-index: 0;
}

.skeleton-container .skeleton-card {
  width: 230px;
  height: 230px;
  z-index: 0;
}

.skeleton-author-container .skeleton-active-sources {
  width: 150px;
  height: 40px;
  border-radius: 16px;
  z-index: 0;
}

.skeleton-answer span {
  margin: 4px;
}

.skeleton-answer span:nth-of-type(2),
.skeleton-answer span:nth-of-type(4),
.skeleton-answer span:nth-of-type(5),
.skeleton-answer span:nth-of-type(8) {
  width: 60%;
}

.skeleton-answer span:nth-of-type(1),
.skeleton-answer span:nth-of-type(3) {
  width: 80%;
}

.date-skeleton {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #6d7f92;
  width: 150px;
  z-index: 0;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.scrollBottom {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}

.active-author-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  width: 90%;
}

.active-author-container h3 {
  font-weight: 500;
  font-size: 16px;
}

.active-author {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #29394D;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 12px;
}

.active-author img {
  width: 42px;
  height: 42px;
  border-radius: 25px;
}

.more-cards {
  display: flex;
  flex-direction: column;
  width: 204px;
  height: 216px;
  color: #29394D;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
}

.more-cards p {
  font-weight: 500;
  size: 14px;
  text-align: center;
}

.cards-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.cards-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 1s ease-in-out;
}

.cards-panel.open {
  transform: translateX(0);
  transition: transform 1s ease-in-out;
}

.cards-panel-header {
  position: fixed;
  width: 70%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6D7F92;
  background-color: #F8F9FA;
  padding: 26px;
  z-index: 1;
}

.cards-panel-title h3 {
  z-index: 1;
}

.cards-panel-title {
  color: #29394D;
}

.cards-panel-close {
  cursor: pointer;
  color: #6D7F92;
  fill: #6D7F92;
}

.cards-panel-content {
  display: flex;
  color: #6D7F92;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  background-color: #FFF;
  align-items: start;
  justify-content: start;
  padding: 20px 20px 20px 30px;
  padding-top: 100px;
  width: 70%;
  position: absolute;
  right: 0;
  height: 100%;
  overflow-y: auto;
}

.cards-panel-content hr {
  width: 100%;
  border-color: #E1E4E8;
  height: 1px;
  margin-top: 15px;
}

.cards-panel-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

.mail-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.mail-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 1s ease-in-out;
}

.mail-panel.open {
  transform: translateX(0);
  transition: transform 1s ease-in-out;
}

.mail-panel-header {
  position: fixed;
  width: 70%;
  height: 144px;
  border-bottom: solid 1px #E1E4E8;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  color: #6D7F92;
  background-color: #F8F9FA;
  padding: 26px;
  z-index: 1;
}

.mail-panel-title h3 {
  z-index: 1;
  font-size: 24px;
  padding-bottom: 25px;
}

.mail-panel-title {
  color: #29394D;
  width: 100%;
  height: 100%;
}

.mail-input {
  padding: 10px 0;
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.mail-panel-cards {
  position: relative;
  padding: 25px 45px 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.mail-panel-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  bottom: 0;
  position: absolute;
  padding: 18px 24px;
  justify-content: end;
  border-top: 1px solid #29294D;
  height: 80px;
}

.mail-panel-buttons a, .mail-panel-buttons button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background: #18A0FB;
  border-radius: 12px;
  width: 180px;
  height: 45px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.edit-save-button {
  background: #18A0FB;
}

.edit-copy-button {
  background: #29394D;
}

.edit-send-button {
  background: #18A0FB;
}

.mail-panel-buttons button:hover, .mail-panel-buttons a:hover {
  opacity: 0.9;
}

.mail-panel-content .mail-input {
  align-items: start; 
}

.mail-input label {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap; /* Prevent text wrapping */
  flex-shrink: 0; /* Prevent label from shrinking */
  width: 120px; /* Set a fixed width for all labels */
  text-align: start; /* Align label text to the right for better alignment */
}

.mail-input input {
  flex: 1; /* Take remaining space */
  height: 44px;
  color: #29394D;
  font-size: 14px;
  font-weight: 400;
  padding: 14px 22px;
  border: solid 1px #B6BFC8;
  line-height: 16.4px;
  border-radius: 5px;
  min-width: 50px; /* Ensure input doesn't collapse completely */
}

.mail-input textarea {
  flex: 1;
  background-color: #F8F9FA;
  min-height: 44px;
  color: #29394D;
  font-size: 14px;
  line-height: 16.4px;
  font-weight: 400;
  resize: none;
  border: solid 1px #B6BFC8;
  border-radius: 5px;
  padding: 14px 22px;
  width: 100%;
  overflow-y: auto; 
}

.mail-panel-close {
  cursor: pointer;
  color: #6D7F92;
  fill: #6D7F92;
}

.mail-panel-content {
  display: flex;
  color: #6D7F92;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  background-color: #FFF;
  align-items: start;
  justify-content: start;
  padding: 25px 0px 0px 0px;
  padding-top: 120px;
  width: 70%;
  position: absolute;
  right: 0;
  height: 100%;
  overflow-y: auto;
}

.mail-panel-content hr {
  width: 100%;
  border-color: #E1E4E8;
  height: 1px;
  margin-top: 15px;
}

.mail-panel-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

.answer a {
  color: #0066cc;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.answer a:hover {
  color: #004999;
  text-decoration: underline;
}

.answer a:visited {
  color: #551A8B;
}

.answer a:active {
  color: #003366;
}

.stream-text {
  transition: all 0.1s ease-out;
}

.stream-text p {
  margin: 0 0 0.6em 0;
  padding: 0;
  min-height: 1.5em;
}

.stream-text p:last-child {
  margin: 0 0 0 0;
}

/******************** media queries *********************/

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .user-question-container {
    width: 100%;
    top: 0;
    right: 0;
    gap: 0px;
    padding: 0px;
    margin-top: 20%;
  }

  .bordered {
    padding-bottom: 5px;
  }

  .user-question-container .answer {
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: justify;
  }

  .skeleton-container .skeleton-card {
    width: 43vw;
    height: 26vh;
  }

  .answer span p {
    width: 100%;
  }

  .date-skeleton {
    width: 150px;
  }

  #sources {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .cards {
    flex-direction: row;
    align-items: start;
    width: 100%;
  }

  .sources-cards {
    grid-template-columns: repeat(2, 0fr);
    gap: 5px;
    width: 100%;
  }

  .active-author {
    margin-top: 5px;
    gap: 5px;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1rem;
    padding: 5px;
    border-radius: 20px;
  }

  .same-rubric-questions {
    grid-column: span 2;
  }

  .skeleton-container {
    gap: 5px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: start;
    align-items: start;
  }

  .skeleton-author-container .skeleton-active-sources {
    width: 90px;
    height: 30px;
    border-radius: 8px;
    z-index: 0;
  }

  .more-cards {
    width: 43vw;
    height: 26vh;
  }

  .more-cards p {
    size: 12px;
  }

  .cards-panel-content {
    width: 100%;
    padding: 100px 10px 30px 10px;
  }

  .cards-panel-header {
    width: 100%;
  }

  .cards-panel-cards {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .mail-input {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: start;
    width: 100%;
}

.mail-input input {
  width: 100%;
}

  .mail-panel {
    width: 100%;
  }

  .mail-panel-content {
    width: 100%;
  }

  .mail-panel-header {
    width: 100%;
    height: 80px;
  }

  .mail-panel-cards {
    padding: 25px 25px 25px 25px;
}

  .mail-panel-buttons {
    justify-content: center;
}

  .skeleton-answer span:nth-of-type(1),
  .skeleton-answer span:nth-of-type(4),
  .skeleton-answer span:nth-of-type(5),
  .skeleton-answer span:nth-of-type(3) {
    width: 100%;
  }

  .skeleton-answer span:nth-of-type(2),
  .skeleton-answer span:nth-of-type(8) {
    width: 70%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .user-question-container {
    width: 100%;
    top: 0;
    right: 10%;
    gap: 10px;
    margin-top: 10%;
  }

  .user-question-container .answer {
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: justify;
  }

  .more-cards p {
    font-weight: 500;
    size: 14px;
    text-align: center;
  }

  .skeleton-container .skeleton-card {
    width: 180px;
    height: 200px;
  }

  .date-skeleton {
    width: 150px;
  }

  #sources {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .cards {
    flex-direction: row;
    align-items: start;
    gap: 20px;
    width: 100%;
  }

  .sources-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .same-rubric-questions {
    grid-column: span 2;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .user-question-container {
    width: 85%;
    top: 0;
    right: 10%;
    gap: 10px;
    margin-top: 10%;
  }

  .user-question-container .answer {
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: justify;
  }

  .skeleton-container .skeleton-card {
    width: 180px;
    height: 200px;
  }

  .date-skeleton {
    width: 150px;
  }

  #sources {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .cards {
    flex-direction: row;
    align-items: start;
    gap: 20px;
    width: 100%;
  }

  .sources-cards {
    grid-template-columns: repeat(3, 0fr);
    gap: 10px;
  }

  .same-rubric-questions {
    grid-column: span 3;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .user-question-container {
    width: 80%;
    padding: 10px;
    gap: 10px;
    margin-top: 5%;
    margin-left: 5%;
  }

  .more-cards p {
    text-align: center;
  }

  .user-question-container .answer {
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: justify;
  }

  .skeleton-container .skeleton-card {
    width: 180px;
    height: 200px;
  }

  .date-skeleton {
    width: 150px;
  }

  .sources {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .cards {
    flex-direction: row;
    align-items: start;
    gap: 20px;
    width: 100%;
  }

  .sources-cards {
    grid-template-columns: repeat(3, 0fr);
    gap: 10px;
  }

  .same-rubric-questions {
    grid-column: span 3;
  }
}

/* Add skeleton styles */
.skeleton-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

.skeleton-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton-card {
  width: 300px;
  height: 216px;
  border-radius: 8px;
}

/* Update existing skeleton styles */
.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.skeleton-answer {
  width: 100%;
  margin-top: 10px;
}

.skeleton-author-container {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.skeleton-active-sources {
  width: 150px;
  height: 40px;
  border-radius: 25px;
}

/* Add loading animation */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.react-loading-skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

/* Add styles for rating section */
.rating-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E1E4E8;
}

.rating-section h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: #29394D;
  margin-bottom: 10px;
}

.bottom-rating {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

@media only screen and (min-width: 360px) {
  .border-bottom {
    width: calc(100vw - 60px);
  }

  .answer .border-bottom {
    width: calc(100vw - 60px);
  }
}

@media only screen and (min-width: 700px) {
  .border-bottom {
    width: calc(100vw - 160px);
  }

  .answer .border-bottom {
    width: calc(100vw - 160px);
  }
}

@media only screen and (min-width: 993px) {
  .bottom-rating {
    width: 80%;
    left: 20%;
  }

  .border-bottom {
    width: calc(100vw - 260px);
  }

  .answer .border-bottom {
    width: calc(100vw - 260px);
  }
}

@media only screen and (min-width: 1201px) {
  .bottom-rating {
    width: 75%;
    left: 25%;
  }

  .border-bottom {
    width: calc(100vw - 320px);
  }

  .answer .border-bottom {
    width: calc(100vw - 320px);
  }
}

.version-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}

.version-button {
  padding: 8px 16px;
  background-color: #f1f2f4;
  border: 0.5px solid #B2BCC6;
  border-radius: 12px;
  color: #29394D;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.version-button:hover {
  background-color: #e1e4e8;
}

@media only screen and (min-width: 360px) {
  .border-bottom {
    width: calc(100vw - 60px);
  }

  .answer .border-bottom {
    width: calc(100vw - 60px);
  }
}

@media only screen and (min-width: 700px) {
  .border-bottom {
    width: calc(100vw - 160px);
  }

  .answer .border-bottom {
    width: calc(100vw - 160px);
  }
}

@media only screen and (min-width: 993px) {
  .bottom-rating {
    width: 80%;
    left: 20%;
  }

  .border-bottom {
    width: calc(100vw - 260px);
  }

  .answer .border-bottom {
    width: calc(100vw - 260px);
  }
}

@media only screen and (min-width: 1201px) {
  .bottom-rating {
    width: 75%;
    left: 25%;
  }

  .border-bottom {
    width: calc(100vw - 320px);
  }

  .answer .border-bottom {
    width: calc(100vw - 320px);
  }
}