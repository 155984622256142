.rating-container {
  width: 96%;
  padding: 20px;
  height: 66px;
  background: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  margin-bottom: -30px;
  border: solid 0.5px #29394D;
}

.rating-header {
  display: flex;
  align-items: center;
  gap: 20px;
  
  span {
    color: #29394D;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  
  .ignore-button {
    background: none;
    border: none;
    color: #6D7F92;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }
}

.stars-container {
  display: flex;
  gap: 8px;
}

.star {
  cursor: pointer;
  color: #E5E7EB;
  transition: all 0.2s ease;
  
  &.filled {
    color: #FFD700;
  }
  
  &:hover {
    color: #FFD700;
  }
  
  svg {
    width: 28px;
    height: 28px;
  }
}

.text-area-container.rating-required {
  .textarea-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 96px;
    position: absolute;
    bottom: 0;
    width: 95%;
  }
}

.text-area-container.open.rating-required {
  .textarea-wrapper {
    width: 85%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .rating-container {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .rating-container {
    width: 78%;
    margin-bottom: 40px;
}
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .rating-container {
    width: 86%;
    margin-right: 8%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .rating-container {
    width: 100%;
    margin-right: 5.5%;
    margin-bottom: -102px;
}
.stars-container {
  gap: 8px;
  margin-left: -18px;
}
  .rating-header {
    span {
      font-size: 0px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}